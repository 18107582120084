import React, { useContext } from 'react';
import { Accordion, AccordionContext, Card, Col, Container, Row, useAccordionButton, ListGroup } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";

function faq() {
    return (
        <Layout>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="FAQ" />
            <Container className="py-5">
                <Row className="justify-content-center">
                    <Col lg={12} className="mb-5">
                        <div className="section-title text-center">
                            <div>Frequently Asked Questions</div>
                            <h3>Frequently Asked Questions</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="mb-md-0 mb-4">
                    <ListGroup variant="flush">
                        <ListGroup.Item href="#link1" active>Ethereum</ListGroup.Item>
                        <ListGroup.Item href="#link2">Getting started</ListGroup.Item>
                        <ListGroup.Item href="#link3">Account questions</ListGroup.Item>
                        <ListGroup.Item href="#link4">NFT</ListGroup.Item>
                        <ListGroup.Item href="#link5">Pre-sale questions</ListGroup.Item>
                        <ListGroup.Item href="#link6">User safety</ListGroup.Item>
                        <ListGroup.Item href="#link7">Partners</ListGroup.Item>
                        <ListGroup.Item href="#link8">Developers</ListGroup.Item>
                    </ListGroup>
                    </Col>
                    <Col md={9}>
                        <Accordion className='accordion-custom' defaultActiveKey="0">
                            <Card>
                                {/* <Card.Header> */}
                                    <ContextAwareToggle eventKey="0">
                                        Where can I buy it?
                                    </ContextAwareToggle>
                                {/* </Card.Header> */}
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <p>Currently HedgePay is available for purchase utilizing our in-house exchange application. <br />Visit https://hedgepay.org/exchange</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                {/* <Card.Header> */}
                                    <ContextAwareToggle eventKey="1">
                                        Do staked tokens count towards the BUSD rewards?
                                    </ContextAwareToggle>
                                {/* </Card.Header> */}
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <p>Tokens kept in the high yield staking buyback mechanisms do not count toward the total amount of HPAY required to generate BUSD rewards. While an investor can choose to stake and and also receive BUSD rewards, the balances are not cumulative.</p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                {/* <Card.Header> */}
                                    <ContextAwareToggle eventKey="2">
                                        What is the maximum supply?
                                    </ContextAwareToggle>
                                {/* </Card.Header> */}
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <p>Maximum supply of HPAY tokens is 1,000,000,000 (1 Billion)</p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                {/* <Card.Header> */}
                                    <ContextAwareToggle eventKey="3">
                                        Why can't I claim rewards?
                                    </ContextAwareToggle>
                                {/* </Card.Header> */}
                                <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <p>To be eligible for the HPAY BUSD reward mechanics, you must hold at least 800k HPAY in your wallet. These rewards can be claimed at any time on a prorated fee basis. These fees are used to supplement the HedgeFi Capital Liquidity and increase rewards to holders. Once early reliance on transactional volume is decoupled from the HedgeFi Capital Liquidity, this threshold will be decreased.</p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                {/* <Card.Header> */}
                                    <ContextAwareToggle eventKey="4">
                                        Is liquidity locked? 
                                    </ContextAwareToggle>
                                {/* </Card.Header> */}
                                <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    <p>Yes, HedgePay utilizes our proprietary zero-risk liquidity locking service. Liquidity is locked biannually and will be automatically renewed 5 days before expiration if no breaking event occurs. This ensures that our ecosystem is secure and adaptable to changing environments. Liquidity locking is transparent and accessible to all members of our community.</p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}

export default faq;

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button
        type="button"
        className={isCurrentEventKey ? 'card-header active' : 'card-header'}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }